<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48">
    <g id="Group_1032" data-name="Group 1032" transform="translate(-15 -18)">
      <g
        id="Path_1636"
        data-name="Path 1636"
        transform="translate(15 18)"
        fill="#fff">
        <path
          d="M 40 47.5 L 8 47.5 C 3.864490032196045 47.5 0.5 44.1355094909668 0.5 40 L 0.5 8 C 0.5 3.864490032196045 3.864490032196045 0.5 8 0.5 L 40 0.5 C 44.1355094909668 0.5 47.5 3.864490032196045 47.5 8 L 47.5 40 C 47.5 44.1355094909668 44.1355094909668 47.5 40 47.5 Z"
          stroke="none" />
        <path
          d="M 8 1 C 4.140190124511719 1 1 4.140190124511719 1 8 L 1 40 C 1 43.85980987548828 4.140190124511719 47 8 47 L 40 47 C 43.85980987548828 47 47 43.85980987548828 47 40 L 47 8 C 47 4.140190124511719 43.85980987548828 1 40 1 L 8 1 M 8 0 L 40 0 C 44.41828155517578 0 48 3.581718444824219 48 8 L 48 40 C 48 44.41828155517578 44.41828155517578 48 40 48 L 8 48 C 3.581718444824219 48 0 44.41828155517578 0 40 L 0 8 C 0 3.581718444824219 3.581718444824219 0 8 0 Z"
          stroke="none"
          fill="#d2d7dc" />
      </g>
      <g id="Group_1503" data-name="Group 1503" transform="translate(23 24)">
        <path
          id="Path_1922"
          data-name="Path 1922"
          d="M17,6a2,2,0,0,1,2,2v9a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V8A2,2,0,0,1,4,6H17"
          transform="translate(-1 -1)"
          fill="#05b5bc" />
        <path
          id="Path_1914"
          data-name="Path 1914"
          d="M16,5a2,2,0,0,1,2,2v9a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V7A2,2,0,0,1,3,5H16m0-1H3A3,3,0,0,0,0,7v9a3,3,0,0,0,3,3H16a3,3,0,0,0,3-3V7a3,3,0,0,0-3-3Z"
          fill="#2d323c" />
        <g id="Group_1502" data-name="Group 1502" transform="translate(13 12)">
          <path
            id="Path_1915"
            data-name="Path 1915"
            d="M42,20H32v1H42a2,2,0,0,1,2,2v9a2,2,0,0,1-2,2H29a2,2,0,0,1-2-2V27H26v5a3,3,0,0,0,3,3H42a3,3,0,0,0,3-3V23A3,3,0,0,0,42,20Z"
            transform="translate(-26 -20)"
            fill="#2d323c" />
        </g>
        <path
          id="Path_1916"
          data-name="Path 1916"
          d="M19,17.826,17.712,19a10.514,10.514,0,0,1-1.5-.451,11.845,11.845,0,0,1-1.494-.7,4.028,4.028,0,0,1-.879-.611,3.151,3.151,0,0,1-.967-.56,2.927,2.927,0,0,1-.75-.973,3.063,3.063,0,0,1-.292-1.371,3.235,3.235,0,0,1,.378-1.536,2.836,2.836,0,0,1,1.162-1.152,3.887,3.887,0,0,1,1.914-.437,4.2,4.2,0,0,1,1.531.257,3,3,0,0,1,1.086.694,2.853,2.853,0,0,1,.632,1,3.353,3.353,0,0,1,.208,1.171,3.121,3.121,0,0,1-.264,1.314,2.884,2.884,0,0,1-.69.958,3.383,3.383,0,0,1-.963.606,8.009,8.009,0,0,0,.989.367,4,4,0,0,0,1.134.147Zm-2.132-3.489a1.847,1.847,0,0,0-.1-.626,1.327,1.327,0,0,0-.306-.492,1.41,1.41,0,0,0-.5-.325,1.848,1.848,0,0,0-.682-.116,1.564,1.564,0,1,0,0,3.125,1.727,1.727,0,0,0,.861-.2,1.32,1.32,0,0,0,.541-.553A1.726,1.726,0,0,0,16.868,14.337Z"
          transform="translate(-5.914 -3.607)"
          fill="#2d323c" />
        <path
          id="Path_1917"
          data-name="Path 1917"
          d="M45,35H43.119l-.5-1.235H39.977L39.472,35H37.591l2.694-6.091h2.03Zm-2.8-2.294-.892-2.224-.9,2.224Z"
          transform="translate(-18.795 -12.455)"
          fill="#2d323c" />
        <path
          id="Path_1918"
          data-name="Path 1918"
          d="M9.333,33A8.014,8.014,0,0,1,7,38s5.134-1.5,7-5Z"
          transform="translate(-3.5 -14.5)"
          fill="#05b5bc" />
        <path
          id="Path_1919"
          data-name="Path 1919"
          d="M6.5,38a.5.5,0,0,1-.365-.841,7.631,7.631,0,0,0,2.2-4.66.5.5,0,0,1,.5-.5H13.5a.5.5,0,0,1,.441.736C12,36.379,6.858,37.917,6.64,37.98A.5.5,0,0,1,6.5,38Zm2.793-5A8.443,8.443,0,0,1,8,36.391,11.71,11.71,0,0,0,12.594,33Z"
          transform="translate(-3 -14)"
          fill="#2d323c" />
        <path
          id="Path_1920"
          data-name="Path 1920"
          d="M44,49c1.867,3.5,7,5,7,5a8.014,8.014,0,0,1-2.333-5Z"
          transform="translate(-22 -22.5)"
          fill="#fff" />
        <path
          id="Path_1921"
          data-name="Path 1921"
          d="M50.5,54a.5.5,0,0,1-.14-.02c-.218-.063-5.358-1.6-7.3-5.244A.5.5,0,0,1,43.5,48h4.667a.5.5,0,0,1,.5.5,7.633,7.633,0,0,0,2.2,4.659A.5.5,0,0,1,50.5,54ZM44.4,49A11.69,11.69,0,0,0,49,52.393,8.439,8.439,0,0,1,47.707,49Z"
          transform="translate(-21.5 -22)"
          fill="#2d323c" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "FAQWithBorder",
};
</script>

<style lang="scss" scoped>
</style>