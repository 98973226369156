<template>
  <div class="mobile-menu light">
    <div class="menu-items">
      <router-link v-if="!isUserLoggedIn" to="/whytjing" class="menu-item"
        >Why Tjing?</router-link
      >
      <router-link v-if="isUserLoggedIn" to="/dashboard" class="menu-item"
        >Dashboard</router-link
      >

      <router-link to="/events/" class="menu-item">Events</router-link>
      <div v-if="isUserLoggedIn" class="menu-item">
        <a class="subMenutrigger" @click="showSubMenu('host')">
          <span>Host</span>
          <span>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-right"
              class="svg-inline--fa fa-chevron-right fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512">
              <path
                fill="currentColor"
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
            </svg>
          </span>
        </a>
      </div>
      <router-link to="/courses" class="menu-item">Courses</router-link>
      <router-link to="/clubs" class="menu-item">Clubs</router-link>
      <router-link to="/pricing" class="menu-item">Pricing</router-link>
      <a href="http://shop.tjing.se" target="_blank" class="menu-item">Shop</a>

      <div class="menu-item">
        <a class="subMenutrigger" @click="showSubMenu('resources')">
          <span>Resources</span>
          <span>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-right"
              class="svg-inline--fa fa-chevron-right fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512">
              <path
                fill="currentColor"
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
            </svg>
          </span>
        </a>
      </div>
      <!-- <router-link
        v-if="foundLiveEvents"
        to="/profile"

        class="menu-item"
      >
        <div class="liveEvent">
          <div class="liveEvent-mobile">
            Live
            <LiveEventIndicator />
          </div>
        </div>
      </router-link>-->
      <div class="menu-item">
        <router-link
          v-if="isUserLoggedIn"
          :to="{ name: 'profile', params: { view: 'basics' } }"
          >Profile settings</router-link
        >
      </div>
      <div class="mobileMenu-buttonSection">
        <router-link
          :to="{
            name: 'login',
            params: {
              source: $router.currentRoute.name,
              params: $router.currentRoute.params,
            },
          }"
          v-if="!$store.getters.isUserLoggedIn">
          <button class="shampoo fluid reversed mt-4">Log in</button>
        </router-link>

        <button
          v-if="isUserLoggedIn"
          class="shampoo fluid mt-4"
          @click="$emit('logout')">
          Log out
        </button>
        <router-link
          :to="{
            name: 'register',
            params: { source: $router.currentRoute.name },
          }"
          v-if="!$store.getters.isUserLoggedIn">
          <button class="shampoo tall fluid mt-4 signUpButton">
            Sign up free
          </button>
        </router-link>
      </div>
    </div>
    <section class="subMenu hidden" ref="host">
      <div class="menu-items">
        <div class="subMenuHeader">
          <span @click="closeSubMenu()">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-left"
              class="svg-inline--fa fa-chevron-left fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512">
              <path
                fill="currentColor"
                d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" />
            </svg>
          </span>
          <div class="subMenuHeader--title">Host</div>
        </div>

        <router-link to="/manage" class="menu-item">
          Manager
          <p>Everything you need to host events</p>
        </router-link>

        <router-link to="/builder" class="menu-item">
          Builder
          <p>Create any type of event</p>
        </router-link>
      </div>
    </section>
    <section class="subMenu hidden" ref="resources">
      <div class="menu-items">
        <div class="subMenuHeader">
          <span @click="closeSubMenu()">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-left"
              class="svg-inline--fa fa-chevron-left fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512">
              <path
                fill="currentColor"
                d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" />
            </svg>
          </span>
          <div class="subMenuHeader--title">Resources</div>
        </div>
        <a class="menu-item" href="/faq">
          FAQ
          <p>Questions we get asked most about</p>
        </a>
        <a class="menu-item" href="https://support.tjing.se">
          Tjing Docs
          <p>In-depth documentation</p>
        </a>
        <a class="menu-item" href="/legal">
          Privacy and Terms
          <p>What we collect and legal agreements</p>
        </a>

        <!-- <router-link class="menu-item" to="/events/upcoming">
          Download
          <p>XXX</p>
        </router-link>-->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  watch: {
    $route: function (newVal) {
      this.$emit("closeModal");
    },
  },
  name: "MobileMenu",
  data() {
    return {
      adminView: false,
      foundLiveEvents: true,
    };
  },
  props: [],
  computed: {
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
  },
  methods: {
    goToRegistration() {
      this.$store.dispatch("toggleMobileMenu", false);
      this.$store.dispatch("toggleRegisterModal", true);
    },
    log(event) {
      console.log(event);
    },
    clickEvent(event) {
      if (event.action == "close") {
        this.$emit("closeModal");
      }
      if (event.action == "execute") {
        this.$emit("execute");
      }
    },
    showSubMenu(ref) {
      this.$refs[ref].classList.remove("hidden");
    },
    closeSubMenu() {
      const subMenus = document.querySelectorAll(".subMenu");
      subMenus.forEach((subMenu) => {
        if (!subMenu.classList.contains("hidden")) {
          subMenu.classList.add("hidden");
        }
      });
    },
  },
  beforeMount() {
    if (this.$router.currentRoute.path.includes("/manage/")) {
      this.adminView = true;
    }
  },
};
</script>

<style lang="scss">
.mobile-menu {
  @include Gilroy-SemiBold;
  position: absolute;
  touch-action: none;
  top: 50px;
  right: 0;
  z-index: 99;
  width: 100vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  transition: all ease 0.5s;
  min-height: calc(100dvh - 50px);
  overflow-x: hidden;
  &.light {
    /* border-top: 1px solid rgba(0, 0, 0, 0.12); */
    background-color: #fff;
    color: $darkText;
  }
}

.menu-items {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 22px;
  .menu-item {
    width: 80%;
    position: relative;
    .subMenutrigger {
      display: flex;
      justify-content: space-between;
      svg {
        height: 20px;
      }
    }
    .liveEvent {
      position: relative;
      .liveEvent-mobile {
        display: flex;
      }
    }
  }
  .signUpButton {
    width: 80%;
  }
}

a {
  color: white;
  text-decoration: none;
  &:active,
  &:hover {
    color: white;
    text-decoration: none;
  }
}
.subMenu {
  position: absolute;
  width: 100vw;
  height: calc(100dvh - 50px);
  background: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  transition: all ease 0.6s;
  left: 0;
  z-index: 11;
  &.hidden {
    left: 120%;
  }
  .subMenuHeader {
    margin-top: 42px;
    position: relative;
    width: 80%;
    text-align: center;
    @include Gilroy-Regular;
    span {
      position: absolute;
      left: 0;
      width: 50px;
      text-align: left;
      cursor: pointer;
      &:hover {
        color: $shampoohover;
      }
      svg {
        width: 15px;
      }
    }
    .subMenuHeader--title {
      @include Gilroy-Bold;
      margin-bottom: 22px;
      font-size: 26px;
    }
  }
}
.mobileMenu-buttonSection {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
  margin: 0 auto;

  button {
    width: 80% !important;
    margin: 0 auto;
  }

  a {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .mobile-menu {
    justify-content: flex-start;

    .menu-items {
      margin-top: 64px;
      justify-content: flex-start;

      .menu-item {
        padding: 1dvh 0 1dvh 0;
      }
    }
  }
  .mobileMenu-buttonSection {
    margin-top: auto;
  }
}
@media (min-width: 1200px) {
  .mobile-menu {
    display: none;
  }
}
</style>
