<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48">
    <g transform="translate(-401 -177)">
      <g class="a" transform="translate(401 177)">
        <rect class="e" width="48" height="48" rx="8" />
        <rect class="f" x="0.5" y="0.5" width="47" height="47" rx="7.5" />
      </g>
      <g transform="translate(110.5 63)">
        <path class="b" d="M304.5,127.5l15.088,7.5v11.5H323.5v-19Z" />
        <path class="c" d="M319.588,146.5H323.5v-19H306.176" />
        <g class="d">
          <path class="e" d="M319.588,154,304.5,146.5v-19l15.088,7.5Z" />
          <path
            class="g"
            d="M 318.5310363769531 152.2938995361328 L 318.5310363769531 135.6550903320312 L 305.5571899414062 129.2061004638672 L 305.5571899414062 145.8449096679688 L 318.5310363769531 152.2938995361328 M 319.5882263183594 154 L 304.5 146.5 L 304.5 127.5 L 319.5882263183594 135 L 319.5882263183594 154 Z" />
        </g>
        <g class="d">
          <path class="e" d="M318.471,122v6H304.5v-.5Z" />
          <path
            class="g"
            d="M 317.4133911132812 123.552360534668 L 308.8013000488281 126.9428100585938 L 317.4133911132812 126.9428100585938 L 317.4133911132812 123.552360534668 M 318.4705810546875 122 L 318.4705810546875 128 L 304.5 128 L 304.5 127.5 L 318.4705810546875 122 Z" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "FolderWithBorder",
};
</script>

<style lang="scss" scoped>
.a {
  fill: #fff;
  stroke: #d2d7dc;
}
.b {
  fill: #05b5bc;
}
.c,
.d,
.f {
  fill: none;
}
.c {
  stroke: #2d323c;
  stroke-width: 1.057px;
}
.c,
.d {
  stroke-miterlimit: 10;
}
.e,
.g {
  stroke: none;
}
.g {
  fill: #2d323c;
}
</style>