<template>
  <img
    class="user-profile-image"
    :class="[size, desktopSize]"
    :src="profileImage"
    v-if="profileImage" />
  <div
    class="user-profile-avatar"
    :class="[size, desktopSize, backgroundColor]"
    v-else>
    {{ initials }}
  </div>
</template>

<script>
export default {
  name: "UserProfileAvatar",
  props: [
    "initials",
    "size",
    "desktopSize",
    "backgroundColor",
    "profileImageUrl",
  ],
  data() {
    return {};
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    profileImage() {
      const imageSizeSuffix = this.size === "XL" ? "_x128." : "_x48.";

      if (this.profileImageUrl) {
        const url = this.profileImageUrl;
        const fileName = url.substring(url.lastIndexOf("/") + 1);
        const newFileName = fileName.replace(".", imageSizeSuffix);
        const newURL = url.replace(fileName, newFileName);

        return newURL;
      }

      if (!this.userInfo.sessionInfo.profileImageUrl || !this.profileImageUrl)
        return null;

      const url = this.userInfo.sessionInfo.profileImageUrl;
      const fileName = url.substring(url.lastIndexOf("/") + 1);
      const newFileName = fileName.replace(".", imageSizeSuffix);
      const newURL = url.replace(fileName, newFileName);

      return newURL;
    },
  },
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.user-profile-image {
  border-radius: 50%;
  background: white;
  width: 32px;
  height: 32px;

  &.S {
    width: 24px;
    height: 24px;
  }
  &.M {
    width: 32px;
    height: 32px;
  }
  &.L {
    width: 40px;
    height: 40px;
  }
  &.XL {
    width: 72px;
    height: 72px;
  }
}
.user-profile-avatar {
  width: 32px;
  min-width: 32px;
  line-height: 32px;
  height: 32px;
  border-radius: 50%;
  background: $midnight;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  @include Gilroy-Bold;
  color: white;
  padding-top: 2px;
  letter-spacing: 0.05rem;

  &.ocean {
    background-color: $ocean;
  }
  &.twilight {
    background-color: $twilight;
  }

  &.S {
    padding-top: 0;
    width: 24px;
    min-width: 24px;
    line-height: 24px;
    height: 24px;
    font-size: 0.725rem;
    letter-spacing: 0.035rem;
  }
  &.M {
    // Default size
  }
  &.L {
    width: 40px;
    min-width: 40px;
    line-height: 40px;
    height: 40px;
    font-size: 1.125rem;
    letter-spacing: 0.05rem;
  }
  &.XL {
    width: 72px;
    min-width: 72px;
    line-height: 72px;
    height: 72px;
    font-size: 2rem;
    letter-spacing: 0.1rem;
  }
}
@media (min-width: 1200px) {
  .user-profile-image {
    &.D-S {
      width: 24px;
      height: 24px;
    }
    &.D-M {
      width: 32px;
      height: 32px;
    }
    &.D-L {
      width: 40px;
      height: 40px;
    }
    &.D-XL {
      width: 72px;
      height: 72px;
    }
  }
  .user-profile-avatar {
    width: 32px;
    min-width: 32px;
    line-height: 32px;
    height: 32px;
    border-radius: 50%;
    background: $midnight;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    @include Gilroy-Bold;
    color: white;
    padding-top: 2px;
    letter-spacing: 0.05rem;

    &.ocean {
      background-color: $ocean;
    }
    &.twilight {
      background-color: $twilight;
    }

    &.S {
      padding-top: 0;
      width: 24px;
      min-width: 24px;
      line-height: 24px;
      height: 24px;
      font-size: 0.725rem;
      letter-spacing: 0.035rem;
    }
    &.D-M {
      width: 32px;
      min-width: 32px;
      line-height: 32px;
      height: 32px;
      font-size: 0.875rem;
      letter-spacing: 0.05rem;
    }
    &.D-L {
      width: 40px;
      min-width: 40px;
      line-height: 40px;
      height: 40px;
      font-size: 1.125rem;
      letter-spacing: 0.05rem;
    }
    &.D-XL {
      width: 72px;
      min-width: 72px;
      line-height: 72px;
      height: 72px;
      font-size: 2rem;
      letter-spacing: 0.1rem;
    }
  }
}
</style>
