<template>
  <div class="status-popup" :class="[messageType]">
    <div class="status-container">
      <p class="success">{{ message.message }}</p>
      <img
        src="@/assets/img/checkmark-notifi-white.svg"
        alt
        v-if="message.type == 'success'"
        @click="closeMessage()" />
      <img
        src="@/assets/img/x-notifi-white.svg"
        alt
        v-if="message.type == 'error'"
        @click="closeMessage()" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["message"],
  name: "StatusPopup",
  methods: {
    closeMessage() {
      this.$store.dispatch("hideMessage");
    },
  },
  computed: {
    messageType() {
      if (this.message.type == "error") {
        return "error";
      } else if (this.message.type == "success") {
        return "success";
      } else if (this.message.type == "warning") {
        return "warning";
      }
      return "warning";
    },
  },
  created() {
    setTimeout(() => {
      this.$store.dispatch("hideMessage");
    }, this.message.timer);
  },
};
</script>

<style lang="scss">
.status-popup {
  transition: all ease 0.3s;
  z-index: 9002;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  height: 50px;
  max-height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
  background-color: $midnight;

  &.error {
    background-color: $dusk;
  }
  &.warning {
    background-color: $sunrise;
  }
  &.success {
    background-color: $avocado;
  }

  color: white;

  .status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 42px;

    img {
      position: absolute;
      right: 15px;
    }
  }

  p {
    text-align: center;
    margin: 0;
    font-size: 14px;
    @include Gilroy-Bold;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .status-popup {
    .status-container {
      img {
        right: 45px;
      }
    }
  }

  p {
    font-size: 16px;
  }
}
</style>
