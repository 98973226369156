<template>
  <div class="verify-email-container">
    <div class="unverified" v-if="!showResendVerification">
      <span class="close-banner" @click="hideBanner()">
        <Crossmark color="white" />
      </span>
      <p class="title">
        <b>Please verify your account</b>
      </p>
      <p class="verify-body">
        Don’t be alarmed, we just want to send you an email to verify you are
        you.
      </p>

      <button class="resend" @click="resendVerification()">Verify now</button>
    </div>

    <div class="resend-verification" v-if="showResendVerification">
      <span class="close-banner" @click="hideBanner()">
        <Crossmark color="white" />
      </span>
      <p class="title">
        <b>Please check your inbox</b>
      </p>

      <p class="verify-body">We’ve sent an email to</p>
      <p class="user-email">
        <b>{{ $store.state.user.email }}</b>
      </p>

      <div class="desktop-verification">
        Please check your inbox We’ve sent an email to
        <b>{{ $store.state.user.email }}</b
        >.
      </div>
    </div>
  </div>
</template>

<script>
import Crossmark from "@/assets/icons/Crossmark.vue";
export default {
  name: "VerifyEmailBanner",
  components: { Crossmark },
  data() {
    return {
      showResendVerification: false,
    };
  },
  computed: {},
  methods: {
    resendVerification() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
            mutation{
              SendVerifyEmail
            }
          `,
        },
      }).then(() => {
        this.showResendVerification = true;
      });
    },
    hideBanner() {
      this.$store.dispatch("hideEmailVerificationBanner");
    },
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.desktop-verification {
  display: none;
}
.verify-email-container {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(239deg, #ff0064 0%, #b40068 100%) 0%
    0% no-repeat padding-box;
  padding: 15px 15px;
  justify-content: flex-start;
  z-index: 10002232003 !important;
  span {
    cursor: pointer;
    @include Gilroy-Bold;
    text-decoration: underline;
  }

  .verify-body {
    width: 100%;
    font-size: 1rem;
    color: white;
    line-height: 1.5rem;
    text-align: center;
    margin: 0;
  }

  .user-email {
    width: 100%;
    font-size: 1rem;
    color: white;
    line-height: 1.5rem;
    text-align: center;
    margin: 0;
  }

  .close-banner {
    margin-left: auto;
  }

  .resend {
    outline: none;
    border: 0;
    background: white;
    color: $twilight;
    width: 100%;
    border-radius: 6px;
    margin: 28px 0;
    transition: all ease 0.3s;

    &:hover {
      background: $twilight;
      color: white;
    }
  }

  .resend-body {
    text-align: center;
    margin-top: 16px;
    color: white;
    width: 100%;
    margin: 28px 0;
  }
  .unverified,
  .resend-verification {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
  .title {
    width: 100%;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .verify-email-container {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $sun;
    padding: 0;
    justify-content: flex-start;

    span {
      cursor: pointer;
      @include Gilroy-Bold;
      text-decoration: underline;
    }

    .close-banner {
      position: absolute;
      top: 24px;
      right: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .title {
      font-size: 32px;
      margin: 48px 0 0 0;
    }
    .verify-body {
      margin-top: 12px;
      font-size: 24px;
    }
    .resend {
      width: auto;
      margin: 32px auto;
      height: 48px;
      font-size: 16px;
    }
  }
  .resend-verification {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 80px 0 96px 0;
    .resend-body,
    .verify-body,
    .user-email {
      display: none;
    }
    .title {
      margin-top: 0;
    }
    .desktop-verification {
      display: block;
      margin-top: 18px;
      font-size: 24px;
      color: white;
    }
  }
}
</style>
