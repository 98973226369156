import "./assets/WebFonts.css";
import "./styles/global-normalize.scss";
import "./styles/global-graphs.scss";
import "vue-resize/dist/vue-resize.css";
import "./assets/bootstrap.min.css";
import "leaflet/dist/leaflet.css";

import moment from "moment";
import Vue from "vue";
import App from "./App.vue";
import store from "@/stores/store";
import router from "./router";
import VueResize from "vue-resize";
import vbclass from "vue-body-class";
import { VueHammer } from "vue2-hammer";
import Meta from "vue-meta";
import NavigationMenu from "./components/NavigationMenu";
import TjingFooter from "./components/Footer";
import axios from "axios";
import PrismicVue from "@prismicio/vue";
import linkResolver from "@/link-resolver.js";
import SweetModal from "sweet-modal-vue/src/plugin.js";
import LoadScript from "vue-plugin-load-script";
import TjingToolTip from "@/components/TjingToolTip";
import L from "leaflet";
import VueObserveVisibility from "vue-observe-visibility";
import TjingPopup from "@/components/TjingPopup";
//import * as Sentry from "@sentry/vue";
//import { BrowserTracing } from "@sentry/tracing";

var VueScrollTo = require("vue-scrollto");

Vue.prototype.moment = moment;

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    if (registrations.length) {
      for (let registration of registrations) {
        registration.unregister();
      }
    }
  });
}
Vue.component("TjingPopup", TjingPopup);
Vue.component("NavigationMenu", NavigationMenu);
Vue.component("TjingFooter", TjingFooter);
Vue.component("TjingToolTip", TjingToolTip);

// We can't use axios 0.25 without adding a url and baseurl for all requests, hopefully this
// is changed in future versions in axios as this does not work well with a single graph endpoint, verbose
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.method = "post";
axios.defaults.timeout = 15000;
axios.defaults.headers.post = {
  "Cache-Control": "no-cache",
  "Content-Type": "application/json",
};
Vue.prototype.$axios = axios;

VueHammer.config = {
  swipe: {
    threshold: 75,
    direction: "horizontal",
  },
  press: {
    time: 250,
    threshold: 75,
  },
};

Vue.use(VueHammer);
Vue.use(SweetModal);
Vue.use(VueScrollTo);
Vue.use(VueResize);
Vue.use(Meta);
Vue.use(LoadScript);
Vue.use(vbclass, router);
Vue.use(PrismicVue, {
  endpoint: "https://tjing.cdn.prismic.io/api/v2",
  linkResolver,
});
Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

router.beforeEach(async (to, from, next) => {
  if (store.getters.authCheckCompleted === false) {
    const authToken = getCookie("authToken");

    // This is the legacy usage and remains to ensure users remain logged in after this change
    const userObject = localStorage.getItem("userObject")
      ? JSON.parse(localStorage.getItem("userObject"))
      : null;

    if (userObject || authToken) {
      await store.dispatch(
        "validateUserAuthState",
        authToken ?? userObject.token
      );
    }
    next();
  }

  if (store.getters.isUserLoggedIn) {
    store.dispatch("checkForOpenGroups", false);
    next();
  }
});
new Vue({
  router,
  store,
  async beforeCreate() {
    document.onkeydown = (evt) => {
      if (evt.code == "Escape") {
        this.$store.dispatch("escapePressed");
      }
    };
  },
  render: (h) => h(App),
}).$mount("#app");
