<template>
  <div
    tabindex="0"
    ref="popup"
    @keydown.esc="blocking ? null : close()"
    class="popup-container"
    :class="{ shown: visible }"
    @touchmove.self.prevent="preventDefault"
    @touchstart.self="blocking ? null : close()"
    @mousedown.self="blocking ? null : close()"
    v-if="show">
    <div
      class="content-container"
      :class="{
        shown: visible,
        hidden: !visible,
      }">
      <div class="close-button" v-if="showCloseButton">
        <span @click="close()">
          <CrossMark />
        </span>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import CrossMark from "@/assets/icons/Crossmark";
export default {
  name: "TjingPopup",
  components: { CrossMark },
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    blocking: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    show: function (newVal) {
      if (newVal) {
        document.body.style.marginRight =
          window.innerWidth - document.documentElement.clientWidth + "px";
        document.body.style.transition = "none";
        document.body.style.overflow = "hidden";

        this.$nextTick(() => {
          this.visible = true;
        });
        this.$nextTick(() => {
          this.$refs.popup.focus();
        });
      }
    },
  },
  computed: {},
  methods: {
    preventDefault(e) {
      e.preventDefault();
    },
    close() {
      this.visible = false;

      setTimeout(() => {
        document.body.style.overflow = "visible";
        document.body.style.marginRight = "0px";
        this.$emit("close");
      }, 300);
    },
  },
  mounted() {},
  beforeCreate() {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";
.close-button {
  margin-top: 12px;
  margin-right: 12px;
  span {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-left: auto;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ease 0.2s;
    svg {
      min-width: 16px;
      min-height: 16px;
    }

    &:hover {
      background-color: $cloud;
    }
  }
}
.popup-container {
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: all ease-in-out 0.3s;
  &.shown {
    background: rgba(125, 130, 150, 0.7);
  }
}
.content-container {
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  scale: unset;
  padding: 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 30px;
  transition: all ease-in-out 0.3s;
  max-width: calc(100% - 24px);
  max-height: calc(100% - 64px);

  .body {
    padding: 0 1rem;
  }
  .footer {
    margin-top: 24px;
    border-top: 1px solid $fog;
    padding: 1.5rem 1rem;
  }

  &.shown {
    animation: enter 0.3s ease-in-out forwards;
  }
  &.hidden {
    animation: exit 0.3s ease-in-out forwards;
  }
  @keyframes exit {
    0% {
      opacity: 1;
      transform: scale(1) translate(0, 0);
    }
    40% {
      opacity: 1;
      transform: scale(1.025) translate(0, 0);
    }
    100% {
      opacity: 0;
      transform: scale(0.01) translate(0, 0);
    }
  }

  @keyframes enter {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .close-button {
    margin-top: 24px;
    margin-right: 24px;
    margin-bottom: 18px;
  }
  .content-container {
    padding: 0;
    border-radius: 16px;
    transition: all ease-in-out 0.3s;
    max-width: 80%;
    max-height: 85%;
    .body {
      padding: 0 2rem 2rem 2rem;
    }
    .footer {
      margin-top: 24px;
      border-top: 1px solid $fog;
      padding: 1.5rem 2rem;
    }
  }
}
</style>
