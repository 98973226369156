<template>
  <div class="home-container">
    <section class="page-header">
      <h1 class="page-title">
        A complete disc golf solution for organisers and players
      </h1>
      <p class="page-sub-title">
        Tjing gives you the power to build, manage and play any kind of
        competition, as well as casual rounds with friends.
      </p>
      <router-link :to="{ name: 'dedicated-why-tjing-players' }">
        <button class="why-tjing">Why Tjing</button>
      </router-link>

      <img
        class="main-splash desktop"
        srcset="@/assets/img/home/Landing-image@2x.png 2x"
        src="@/assets/img/home/Landing-image@1x.png"
        alt="Livescoring with app scorecard" />
      <img
        class="main-splash mobile"
        srcset="@/assets/img/home/mobile_splash@2x.png 2x"
        src="@/assets/img/home/mobile_splash@1x.png"
        alt="Livescoring on mobile" />
    </section>
    <section class="midnight">
      <div class="sub-section">
        <p class="section-title">
          <b>
            With it comes live scoring, leaderboards, scorecards and detailed
            player stats.</b
          >
        </p>
        <p class="section-sub-title">
          A smooth digital discgolf scoring experience for stroke play, match
          play, doubles and team rounds. Track, analyse and measure every aspect
          of your activity.
        </p>
      </div>
      <div class="sub-section get-app">
        <p class="download-text"><b>Download for free on iOS and Android</b></p>
        <a
          href="https://apps.apple.com/se/app/tjing/id1277060479"
          v-if="shouldShowIOSButton">
          <img
            class="storeButtonImage"
            srcset="@/assets/img/home/App_Store_Black_Button@2x.png 2x"
            src="@/assets/img/home/App_Store_Black_Button.png" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.kidpilot.tjing"
          v-if="shouldShowAndroidButton">
          <img
            class="storeButtonImage"
            srcset="@/assets/img/home/Google_Play_Black_Button@2x.png 2x"
            src="@/assets/img/home/Google_Play_Black_Button.png"
            alt="Google Play" />
        </a>
      </div>
    </section>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      show: false,
      dataFromUserPlatform: null,
    };
  },
  computed: {
    isMobilePlatform() {
      return /iPhone|iPad|iPod|Android/i.test(this.dataFromUserPlatform);
    },
    shouldShowAndroidButton() {
      if (this.isMobilePlatform) {
        return /iPhone|iPad|iPod/i.test(this.dataFromUserPlatform)
          ? false
          : true;
      }
      return true;
    },
    shouldShowIOSButton() {
      if (this.isMobilePlatform) {
        return /Android/i.test(this.dataFromUserPlatform) ? false : true;
      }
      return true;
    },
  },
  mounted() {
    this.dataFromUserPlatform = navigator.platform + " " + navigator.userAgent;
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/Typography";
a,
a:hover {
  text-decoration: none;
}
.home-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.page-header {
  text-align: center;
  padding: 0 1rem;
}
.page-title {
  @include Gilroy-Bold;
  margin: 0;
  font-size: 2rem;
  line-height: 2.75rem;
  text-align: center;
}
.page-sub-title {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin-top: 1rem;
}
.why-tjing {
  margin: 2rem auto 3rem auto;
  display: flex;
  height: 3rem;
  font-size: 1rem;
  background: $twilight;
  border: 2px solid $twilight;
  color: white;
  border-radius: 6px;
}
.main-splash {
  &.desktop {
    display: none;
  }
  margin: 0 auto;
  max-width: 70%;
}
section {
  &.midnight {
    padding: 2rem 1rem 2.5rem 1rem;
    background: $midnight;
    p {
      color: white;
    }

    .sub-section {
      &.get-app {
        text-align: center;
        margin-top: 2rem;
        p {
          text-align: center;
          width: 100%;
        }

        img {
          margin-top: 1.5rem;
          width: auto;
        }
      }
    }
  }

  .section-title {
    text-align: center;
    font-size: 1.75rem;
    line-height: 2.625rem;
  }
  .section-sub-title {
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .home-container {
    padding: 0 1rem;
  }

  .main-splash {
    &.desktop {
      display: block;
      max-width: unset;
    }
    &.mobile {
      display: none;
    }
  }
}

@media (min-width: 1200px) {
  .why-tjing {
    margin-bottom: 0;
  }
  .home-container {
    margin-top: 5rem;
    padding: 0 0.875rem;
    display: flex;
    flex-direction: column;

    .page-title {
      margin: 0;
      font-size: 4rem;
      line-height: 4.875rem;
      padding: 0 10rem;
      text-align: center;
    }
    .page-sub-title {
      text-align: center;
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 2rem;
      padding: 0 6rem;
    }

    .main-splash {
      margin-top: 4rem;
    }
  }
  .page-header {
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1208px;
  }
  section {
    &.midnight {
      padding: 4rem 0 5rem 0;
      background: $midnight;
      gap: 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: white;
      }

      .sub-section {
        width: 596px;

        &.get-app {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 2rem;
          p {
            font-size: 1.25rem;
            text-align: center;
            width: 100%;
          }

          img {
            margin-top: 0;
          }
        }
      }
    }

    .section-title {
      text-align: left;
      font-size: 3rem;
      line-height: 4.5rem;
    }
    .section-sub-title {
      text-align: left;
      margin-top: 1rem;
      font-size: 1.125rem;
      line-height: 1.6875rem;
    }
  }
}
</style>
