import { render, staticRenderFns } from "./Crossmark.vue?vue&type=template&id=218e2352&scoped=true&"
import script from "./Crossmark.vue?vue&type=script&lang=js&"
export * from "./Crossmark.vue?vue&type=script&lang=js&"
import style0 from "./Crossmark.vue?vue&type=style&index=0&id=218e2352&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "218e2352",
  null
  
)

export default component.exports