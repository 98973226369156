<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48">
    <g transform="translate(-398 -259)">
      <g transform="translate(-3 82)">
        <g class="a" transform="translate(401 177)">
          <rect class="e" width="48" height="48" rx="8" />
          <rect class="f" x="0.5" y="0.5" width="47" height="47" rx="7.5" />
        </g>
      </g>
      <path
        class="b"
        d="M9,29H29V5.5L24.5,1H9Z"
        transform="translate(403 268)" />
      <circle class="c" cx="5" cy="5" r="5" transform="translate(417 283)" />
      <line class="d" x2="12" transform="translate(416 279)" />
      <line class="d" x2="6" transform="translate(416 276)" />
      <path class="d" d="M45,2V7h5" transform="translate(382 267)" />
      <path
        class="d"
        d="M28,43l1.5,1.5L32,42"
        transform="translate(392 244.5)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "TermsAndConditionWithBorder",
};
</script>

<style lang="scss" scoped>
.a,
.b {
  fill: #fff;
}
.a {
  stroke: #ebeaeb;
}
.b,
.c,
.d {
  stroke: #2d323c;
  stroke-miterlimit: 10;
}
.c {
  fill: #05b5bc;
}
.d,
.f {
  fill: none;
}
.e {
  stroke: none;
}
</style>