import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import store from "@/stores/store";

Vue.use(Router);

const disabledScrollPathNames = [
  "events",
  // Clubs
  "club",
  "club-posts",
  "club-members",
  "club-member-tiers",
  "club-pending-members",
  "club-settings",
  "club-events",
  //Organizer
  "promoter",
  "promoter-posts",
  "promoter-settings",
  "promoter-events",
  "promoter-leaderboard",
  "promoter-members",
  "promoter-pending-members",

  // Association
  "association",
  "association-posts",
  "association-license",
  "association-license-tiers",
  "association-clubs",
  "association-clubs-pending",
  "association-settings",
  "association-events",
  "event-shop-orders",
  "event-shop-items",
  "event-shop-account",
  "why-tjing-players",
  "why-tjing-organisers",
  "why-tjing-clubs",
  "event-rounds-beta",
  "manage-event-round",
  "event-players-confirmed",
  "event-players-waiting",
  "event-players-cancelled",
  "event-players-utilities",
  "tournamentoverview-stats",
  "eventoverview-stats",
  "public-event-division-results",
  "public-event-pool-division-results",
  "public-event-pool-stats",
  "public-event-players",
  "public-event-teams",
  "event-edit-results",
  "event-edit-tiebreaks",
  // Tour
  "tournamentoverview-home",
  "tournamentoverview-standings",
  "tournamentoverview-division-standings",
  "tournamentoverview-league-standings",
  "tournamentoverview-league-division-standings",
  "tournamentoverview-events",
  "tournamentoverview-stats",
];

export default new Router({
  scrollBehavior(to, from, savedPosition) {
    if (disabledScrollPathNames.includes(to.name) && !to.params.scrollToTop) {
      return;
    }
    if (to.hash) {
      return { left: 0, top: 0 };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
  routes: [
    {
      path: "*",
      name: "404",
      component: () => import("@/views/404.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "404 - Not Found";
        next();
      },
    },
    {
      path: "/",
      meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
      component: Home,
      beforeEnter: (to, from, next) => {
        document.title = "Tjing";
        next();
      },
      children: [
        {
          path: "/",
          meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
          component: () => import("@/views/WhyTjingWrapper.vue"),
          children: [
            {
              path: "/",
              name: "why-tjing-players",
              meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
              component: () => import("@/views/WhyTjingPlayers.vue"),
              beforeEnter: (to, from, next) => {
                document.title = "Tjing";
                next();
              },
            },
            {
              path: "/why-tjing/organisers",
              name: "why-tjing-organisers",
              meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
              component: () => import("@/views/WhyTjingOrganisers.vue"),
              beforeEnter: (to, from, next) => {
                document.title = "Tjing";
                next();
              },
            },
            {
              path: "/why-tjing/clubs",
              name: "why-tjing-clubs",
              meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
              component: () => import("@/views/WhyTjingClubs.vue"),
              beforeEnter: (to, from, next) => {
                document.title = "Tjing";
                next();
              },
            },
          ],
        },
      ],
    },
    {
      path: "/prismic",
      name: "prismic",
      meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
      component: () => import("@/prismic/Prismic.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Prismic Content";
        next();
      },
    },
    {
      path: "/about",
      name: "about",
      meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
      component: () => import("@/views/About.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - About";
        next();
      },
    },
    {
      path: "/pricing",
      name: "pricing",
      meta: { bodyClass: "full noMinHeight noTopMarginBody" },
      component: () => import("@/pages/Pricing.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Pricing";
        next();
      },
    },
    {
      path: "/faq",
      name: "faq",
      meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
      component: () => import("@/prismic/FAQ.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - FAQ";
        next();
      },
    },
    {
      path: "/hasard",
      name: "hasard",
      meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
      component: () => import("@/gadgets/Hasard.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Hasard";
        next();
      },
    },
    {
      path: "/measure",
      name: "measure",
      meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
      component: () => import("@/gadgets/ThrowMeassuring.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Measure";
        next();
      },
    },
    {
      path: "/contact",
      name: "contact",
      meta: { bodyClass: "full noTopMarginFooter noMinHeight" },
      component: () => import("@/views/Contact.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Contact";
        next();
      },
    },
    {
      path: "/career",
      name: "career",
      meta: { bodyClass: "noTopMarginFooter noMinHeight" },
      component: () => import("@/pages/OpenPositions.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Positions";
        next();
      },
    },
    {
      path: "/user/:userId",
      name: "public-user",
      meta: { bodyClass: "noTopMarginFooter noMinHeight" },
      component: () => import("@/pages/PublicProfile.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - User profile";
        next();
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/UserDashboard.vue"),
      redirect: { name: "dashboard-activity" },
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Dashboard";
        next();
      },
      children: [
        {
          path: "activity",
          name: "dashboard-activity",
          component: () => import("@/pages/DashboardActivity.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Activity";
            next();
          },
        },
        {
          path: "progress",
          name: "dashboard-progress",
          component: () => import("@/pages/DashboardProgress.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Progress";
            next();
          },
        },
        {
          path: "starred",
          name: "dashboard-starred",
          component: () => import("@/pages/DashboardFavourites.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Starred";
            next();
          },
        },
      ],
    },
    {
      path: "/whytjing/",
      meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
      component: () => import("@/views/WhyTjingDedicatedWrapper.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Why Tjing";
        next();
      },
      children: [
        {
          path: "/",
          name: "dedicated-why-tjing-players",
          meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
          component: () => import("@/views/WhyTjingPlayers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing";
            next();
          },
        },
        {
          path: "/whytjing/organisers",
          name: "dedicated-why-tjing-organisers",
          meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
          component: () => import("@/views/WhyTjingOrganisers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing";
            next();
          },
        },
        {
          path: "/whytjing/clubs",
          name: "dedicated-why-tjing-clubs",
          meta: { bodyClass: "full noTopMarginFooter noTopMarginBody" },
          component: () => import("@/views/WhyTjingClubs.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing";
            next();
          },
        },
      ],
    },
    {
      path: "/blog",
      name: "blog",
      component: () => import("@/views/Blog.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Blog";
        next();
      },
    },
    {
      path: "/gratitude",
      name: "gratitude",
      component: () => import("@/views/Gratitude.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Gratitude Wall";
        next();
      },
    },
    {
      //legacy path
      path: "/profile",
      redirect: { name: "profile", params: { view: "basics" } },
    },
    {
      path: "/profile/upload/",
      name: "profileimageupload",
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/ProfileImageUpload.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Profile image upload";
        next();
      },
    },
    {
      path: "/profile/:view",
      name: "profile",
      component: () => import("@/views/Profile.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Profile";
        next();
      },
    },
    {
      path: "/login/",
      name: "login",
      meta: { bodyClass: "hideMenuitems hideFooter noMinHeight" },
      component: () => import("@/components/TheLoginPage.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Login";
        next();
      },
    },
    {
      path: "/login/:source", // <- Legacy route
      name: "login-legacy",
      meta: { bodyClass: "hideMenuitems hideFooter noMinHeight" },
      component: () => import("@/components/TheLoginPage.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Login";
        next();
      },
    },

    {
      path: "/register",
      name: "register",
      meta: { bodyClass: "hideMenuitems hideFooter noMinHeight" },
      component: () => import("@/components/TheCreateAccountPage.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Get Started";
        next();
      },
    },
    {
      path: "/legal",
      name: "legal",
      component: () => import("@/views/Legal.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Legal";
        next();
      },
      children: [
        {
          path: "terms",
          name: "terms",
          component: () => import("@/components/TermsOfService.vue"),
        },
        {
          path: "privacy",
          name: "privacy",
          component: () => import("@/components/PrivacyPolicy.vue"),
        },
      ],
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      meta: { bodyClass: "hideMenuitems hideFooter noMinHeight" },
      component: () => import("@/components/TheForgotPasswordPage.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Reset Password";
        next();
      },
    },
    {
      path: "/verifyemail",
      name: "verifyemail",
      component: () => import("@/components/TheEmailVerifier.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Verify Email";
        next();
      },
    },
    {
      path: "/casual/scorecard/:scorecardId",
      name: "casual-scorecards",
      meta: { bodyClass: "noTopMarginBody " },
      component: () => import("@/pages/casual/LiveCasualScorecard.vue"),
    },
    {
      path: "/scorecard/",
      name: "scorecard",
      component: () => import("@/components/scorecard/TheScorecard.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Scorecard";
        store.dispatch("setMainNavState", false);
        next();
      },
      children: [
        {
          path: "choose",
          name: "scorecard-picker",
          component: () =>
            import("@/components/scorecard/TheScorecardPicker.vue"),
        },
        {
          path: "group-scoring",
          name: "group-scoring-mode",
          meta: { bodyClass: "full " },
          component: () =>
            import("@/components/scorecard/TheGroupScoringInformation.vue"),
        },
        {
          path: ":groupId/marker",
          name: "marker",
          meta: { bodyClass: "midnightbg" },
          component: () => import("@/components/scorecard/TheMarker.vue"),
        },
        {
          path: ":groupId/teammarker",
          name: "teammarker",
          meta: { bodyClass: "midnightbg" },
          component: () => import("@/components/scorecard/TheTeamMarker.vue"),
        },
        {
          path: ":groupId/verifier",
          name: "verifier",
          meta: { bodyClass: "midnightbg" },
          component: () => import("@/components/scorecard/TheVerifier.vue"),
        },
        {
          path: ":groupId/teamverifier",
          name: "teamverifier",
          meta: { bodyClass: "midnightbg" },
          component: () => import("@/components/scorecard/TheTeamVerifier.vue"),
        },
        {
          path: ":groupId/stattracker",
          name: "stattracker",
          meta: { bodyClass: "midnightbg" },
          component: () =>
            import("@/components/scorecard/ThePersonalStatTracker.vue"),
        },
        {
          path: ":groupId/tracker",
          name: "tracker",
          component: () => import("@/components/TheTracker.vue"),
        },
      ],
    },
    {
      path: "/customcourses",
      name: "customcoursedirectory",
      meta: { bodyClass: "noTopMarginFooter" },
      component: () => import("@/components/TjingAdminCourseManager.vue"),
    },
    {
      path: "/courses",
      name: "coursedirectory",
      component: () => import("@/pages/course/PublicCourseSearchPage.vue"),
    },
    {
      path: "/courses/request/new",
      name: "new-course-request",
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/course/CourseRequest.vue"),
    },
    {
      path: "/courses/:courseId/",
      name: "course-overview",
      component: () => import("@/pages/course/PublicCoursePage.vue"),
    },
    {
      path: "/courses/:courseId/guardianship",
      name: "new-course-guardian",
      meta: { bodyClass: "desktop-mist-background " },
      component: () => import("@/pages/course/CourseGuardianRequest.vue"),
    },
    {
      path: "/courses/:courseId/edit",
      name: "course-edit",
      meta: { bodyClass: "desktop-mist-background " },
      component: () => import("@/pages/course/EditCoursePage.vue"),
    },
    {
      path: "/courses/:courseId/layout-request",
      name: "course-layout-request",
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/course/LayoutRequest.vue"),
    },
    {
      path: "/courses/:courseId/edit-points-of-interest",
      name: "edit-course-points-of-interest",
      meta: { bodyClass: "noTopMarginFooter no-scroll" },
      component: () => import("@/pages/course/CourseMapEditor.vue"),
    },
    {
      path: "/courses/:courseId/layout/:layoutId",
      name: "course-layout",
      component: () => import("@/pages/course/PublicLayoutPage.vue"),
    },
    {
      path: "/courses/:courseId/layout/:layoutId/request-update",
      name: "course-layout-update-request",
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/course/LayoutRequest.vue"),
    },
    {
      path: "/courses/:courseId/layout/:layoutId/review",
      name: "course-layout-review",
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/course/UserLayoutReview.vue"),
    },
    {
      path: "/courses/:courseId/layout/:layoutId/edit-review",
      name: "course-layout-edit-review",
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/course/UserLayoutReview.vue"),
    },
    {
      path: "/courses/:courseId/layout/:layoutId/edit",
      name: "course-layout-editor",
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/course/EditLayoutPage.vue"),
    },
    {
      path: "/courses/:courseId/layout/:layoutId/mapedit",
      name: "course-layout-map-editor",
      meta: { bodyClass: "noTopMarginFooter no-scroll" },
      component: () => import("@/pages/course/LayoutMapEditor.vue"),
    },
    {
      path: "/events",
      name: "events",
      meta: { bodyClass: "full noTopMarginBody " },
      component: () => import("@/views/Events.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Events";
        next();
      },
    },
    {
      path: "/event-invitation/:invitationId",
      name: "event-invitation",
      meta: { bodyClass: "full noTopMarginBody " },
      component: () => import("@/pages/EventInvitation.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Event invitation";
        next();
      },
    },
    {
      //legacy path
      path: "/events/upcoming",
      redirect: { name: "events" },
    },
    {
      path: "/eventsclassic/:tab",
      name: "specifiedevents",
      props: true,
      component: () => import("@/views/Events.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Events";
        next();
      },
    },
    {
      path: "/manage",
      name: "manage",
      component: () => import("@/views/OrganizerManagerOverview.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Manager";
        next();
      },
    },
    {
      path: "/manage/tour/transfer/:token",
      name: "transfer-tour",
      meta: { bodyClass: "mist-background" },
      component: () => import("@/pages/TourTransfer.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Tour transfer";
        next();
      },
    },
    {
      path: "/manage/tour/:tourId",
      component: () => import("@/views/OrganizerTourOverview.vue"),
      children: [
        {
          path: "/manage/tour/:tourId/upcoming",
          name: "tour-overview",
          component: () => import("@/components/ManagerTourEvents.vue"),
        },
        {
          path: "settings",
          name: "tour-overview-settings",
          component: () => import("@/components/TourSettings.vue"),
        },
        {
          path: "export",
          name: "tour-overview-export",
          component: () => import("@/pages/TourExport.vue"),
        },
        {
          path: "addevent",
          name: "tour-overview-add-event",
          component: () => import("@/components/AddNewEvent.vue"),
        },
        {
          path: "divisions",
          name: "tour-overview-divisions",
          component: () => import("@/components/TourDivisions.vue"),
        },
        {
          path: "tjingpay",
          name: "tour-overview-tjingpay",
          component: () => import("@/pages/TourTjingPay.vue"),
        },
      ],
    },
    {
      path: "/manage/tour/:tourId/create-custom-points",
      name: "create-custom-points",
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/CreateCustomPointSystem.vue"),
    },
    {
      path: "/manage/tour/:tourId/update-custom-points/:systemId",
      name: "update-custom-points",
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/UpdateCustomPointSystem.vue"),
    },
    {
      path: "/courses",
      name: "coursemanager",
      props: true,
      component: () => import("@/components/CourseManager.vue"),
    },
    {
      path: "/manage/courses/",
      name: "coursemanagerbeta",
      props: true,
      meta: { bodyClass: "noTopMarginFooter" },
      component: () => import("@/components/TjingAdminCourseManager.vue"),
    },
    {
      path: "/manage/:eventId/rounds-beta/:poolId/groups",
      name: "event-round-groups",
      component: () => import("@/pages/manager/rounds/PoolGroupManager.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Groups manager";
        next();
      },
    },
    {
      path: "/managedevent",
      name: "eventmanager",
      meta: { bodyClass: "" },
      component: () => import("@/components/EventManager.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Manager";
        next();
      },
      children: [
        {
          path: "/manage/:eventId/overview",
          name: "event-overview",
          component: () => import("@/components/TheEventTimeline.vue"),
        },
        {
          path: "/manage/:eventId/teams/",
          name: "event-teams",
          component: () =>
            import("@/pages/manager/players/TheEventRegistrations.vue"),
          children: [
            {
              path: ":divisionId/confirmed/",
              name: "event-teams-confirmed",
              component: () =>
                import("@/pages/manager/players/ConfirmedPlayers.vue"),
            },
            {
              path: ":divisionId/waiting/",
              name: "event-teams-waiting",
              component: () =>
                import("@/pages/manager/players/WaitingPlayers.vue"),
            },
            {
              path: ":divisionId/cancelled/",
              name: "event-teams-cancelled",
              component: () =>
                import("@/pages/manager/players/CancelledPlayers.vue"),
            },
            {
              path: "utilities",
              name: "event-teams-utilities",
              component: () => import("@/pages/manager/players/Utilities.vue"),
            },
          ],
        },
        {
          path: "/manage/:eventId/players/",
          name: "event-players",
          component: () =>
            import("@/pages/manager/players/TheEventRegistrations.vue"),
          children: [
            {
              path: ":divisionId/confirmed/",
              name: "event-players-confirmed",
              component: () =>
                import("@/pages/manager/players/ConfirmedPlayers.vue"),
            },
            {
              path: ":divisionId/waiting/",
              name: "event-players-waiting",
              component: () =>
                import("@/pages/manager/players/WaitingPlayers.vue"),
            },
            {
              path: ":divisionId/cancelled/",
              name: "event-players-cancelled",
              component: () =>
                import("@/pages/manager/players/CancelledPlayers.vue"),
            },
            {
              path: "utilities",
              name: "event-players-utilities",
              component: () => import("@/pages/manager/players/Utilities.vue"),
            },
          ],
        },
        {
          path: "/manage/:eventId/edit-results/",
          name: "",
          component: () => import("@/pages/ManagerResultsWrapper.vue"),
          children: [
            {
              path: ":divisionId/scores",
              name: "event-edit-results",
              component: () => import("@/pages/PublicEventStandings.vue"),
            },
            {
              path: ":divisionId/team-scores",
              name: "event-edit-team-results",
              component: () => import("@/pages/PublicTeamEventStandings.vue"),
            },
            {
              path: ":divisionId/tiebreaks",
              name: "event-edit-tiebreaks",
              component: () => import("@/pages/ManagerEventTiebreaks.vue"),
            },
            {
              path: "export",
              name: "event-result-export",
              component: () => import("@/pages/ManagerResultUtilities.vue"),
            },
          ],
        },
        {
          path: "/manage/:eventId/contact",
          name: "event-contact",
          component: () => import("@/components/EventContactDetails.vue"),
        },
        {
          path: "/manage/:eventId/contact/upload",
          name: "event-file-upload",
          component: () => import("@/pages/EventFileUpload.vue"),
        },
        {
          path: "/manage/:eventId/messages",
          name: "event-messages",
          component: () => import("@/components/EventMessages.vue"),
        },
        {
          path: "/manage/:eventId/settings",
          name: "event-settings",
          component: () => import("@/components/EventSettings.vue"),
        },
        {
          path: "/manage/:eventId/addevent/:tourId",
          name: "one-off-settings-add-event",
          meta: { bodyClass: "mist-background" },
          component: () => import("@/components/AddNewEvent.vue"),
        },
        {
          path: "/manage/:eventId/oneoffsettings",
          name: "one-off-event-settings",
          component: () => import("@/components/OneOffEventSettings.vue"),
        },
        {
          path: "/manage/:eventId/rounds",
          name: "event-rounds",
          component: () => import("@/pages/manager/rounds/Rounds.vue"),
          children: [
            {
              path: ":roundId",
              name: "manage-event-round",
              component: () => import("@/pages/manager/rounds/Round.vue"),
            },
          ],
        },
        {
          path: "/manage/:eventId/inactiveshop",
          name: "inactive-shop",
          component: () => import("@/pages/InactiveShop.vue"),
        },

        {
          path: "/manage/:eventId/shop/",
          name: "event-shop-manager",
          component: () => import("@/views/EventShopManager.vue"),
          children: [
            {
              path: "orders",
              name: "event-shop-orders",
              component: () => import("@/pages/EventShopOrders.vue"),
            },
            {
              path: "items",
              name: "event-shop-items",
              component: () => import("@/pages/EventShopItems.vue"),
            },
            {
              path: "account",
              name: "event-shop-account",
              component: () => import("@/pages/EventShopAccount.vue"),
            },
            {
              path: "createitem",
              name: "create-shop-item",
              component: () => import("@/pages/CreateShopItem.vue"),
            },
            {
              path: "updateitem/:itemId",
              name: "update-shop-item",
              component: () => import("@/pages/UpdateShopItem.vue"),
            },
            {
              path: "product-images/upload",
              name: "upload-event-shop-images",
              component: () => import("@/pages/EventShopItemImageUpload.vue"),
            },
          ],
        },
        {
          path: "/manage/:eventId/groupeditor/:roundId/",
          name: "event-groups-round",
          component: () => import("@/components/GroupEditor.vue"),
        },
      ],
    },
    {
      path: "/manage/:eventId/registrationquestions",
      name: "event-manage-registration-questions",
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import(
          "@/pages/registrationquestions/RegistrationQuestionsManager.vue"
        ),
    },
    {
      path: "/manage/:eventId/create-registration-question",
      name: "create-registration-question",
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/registrationquestions/CreateRegistrationQuestion.vue"),
    },
    {
      path: "/builder/",
      name: "",
      component: () => import("@/views/Builder.vue"),
      meta: { bodyClass: "mist-background" },
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Builder";
        next();
      },
      children: [
        {
          path: "",
          name: "builder",
          component: () => import("@/components/builder/Home.vue"),
        },
        {
          path: "oneoff",
          name: "singleevent",
          component: () => import("@/components/builder/SingleEvent.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Single event builder";
            next();
          },
          children: [
            {
              path: "mandatory",
              name: "singleeventmandatory",
              component: () =>
                import("@/components/builder/SingleEventMandatory.vue"),
            },
            {
              path: "features",
              name: "singleeventfeatures",
              component: () =>
                import("@/components/builder/SingleEventFeatures.vue"),
            },
            {
              path: "settings",
              name: "singleeventsettings",
              component: () =>
                import("@/components/builder/SingleEventSettings.vue"),
            },
            {
              path: "optional",
              name: "singleeventoptional",
              component: () =>
                import("@/components/builder/SingleEventOptional.vue"),
            },
          ],
        },
        {
          path: "tournament",
          name: "tournament",
          component: () => import("@/components/builder/Tournament.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Tournament builder";
            next();
          },
          children: [
            {
              path: "details",
              name: "tournamentdetails",
              component: () =>
                import("@/components/builder/TournamentDetails.vue"),
            },

            {
              path: "events",
              name: "tournamenteventdetails",
              component: () =>
                import("@/components/builder/TournamentEventDetails.vue"),
            },
          ],
        },
        {
          path: "league",
          name: "weeklytour",
          component: () => import("@/components/builder/League.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - League builder";
            next();
          },
          children: [
            {
              path: "mandatory",
              name: "leaguesmandatory",
              component: () =>
                import("@/components/builder/LeaguesMandatory.vue"),
            },
            {
              path: "settings",
              name: "leaguessettings",
              component: () =>
                import("@/components/builder/LeaguesSettings.vue"),
            },
            {
              path: "features",
              name: "leaguesfeatures",
              component: () =>
                import("@/components/builder/LeaguesFeatures.vue"),
            },
            {
              path: "optional",
              name: "leaguesoptional",
              component: () =>
                import("@/components/builder/LeaguesOptional.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/tour/:tourId/",
      meta: { bodyClass: "noTopMarginBody" },
      component: () => import("@/views/PublicTourOverview.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Tour overview";
        next();
      },
      children: [
        {
          path: "",
          name: "tournamentoverview-home",
          component: () => import("@/pages/PublicTourHome.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Tour";
            next();
          },
        },
        {
          path: "standings",
          name: "tournamentoverview-standings",
          component: () => import("@/pages/PublicTourStandings.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Tour overview";
            next();
          },
        },
        {
          path: "standings/:divisionId",
          name: "tournamentoverview-division-standings",
          component: () => import("@/pages/PublicTourStandings.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Tour overview";
            next();
          },
        },
        {
          path: "standings/all",
          name: "tournamentoverview-league-standings",
          component: () => import("@/pages/PublicTourStandings.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Tour overview";
            next();
          },
        },
        {
          path: "standings/:divisionId/all",
          name: "tournamentoverview-league-division-standings",
          component: () => import("@/pages/PublicTourStandings.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Tour overview";
            next();
          },
        },
        {
          path: "events",
          name: "tournamentoverview-events",
          component: () => import("@/pages/PublicTourEventsList.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Tour overview";
            next();
          },
        },
        {
          path: "stats/:divisionId/:view",
          name: "tournamentoverview-stats",
          component: () => import("@/pages/PublicTourStats.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Tour overview";
            next();
          },
        },
      ],
    },
    {
      path: "/event/:eventId/round",
      name: "public-event-round",
      meta: { bodyClass: "noTopMarginBody" },
      component: () => import("@/views/PublicEventRound.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Event rounds";
        next();
      },
      children: [
        {
          path: ":poolId/results",
          name: "public-event-pool-results",
          component: () => import("@/pages/PublicEventPoolResults.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event rounds";
            next();
          },
        },
        {
          path: ":poolId/results/:divisionId",
          name: "public-event-pool-division-results",
          component: () => import("@/pages/PublicEventPoolResults.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event rounds";
            next();
          },
        },
        {
          path: ":poolId/groups",
          name: "public-event-pool-groups",
          component: () => import("@/pages/PublicEventPoolGroups.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event rounds";
            next();
          },
        },
        {
          path: ":poolId/stats/:divisionId/:view",
          name: "public-event-pool-stats",
          component: () => import("@/pages/PublicEventPoolStats.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event rounds";
            next();
          },
        },
        {
          path: ":poolId/compare",
          name: "public-event-pool-compare",
          component: () => import("@/pages/PublicEventPoolCompare.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event rounds";
            next();
          },
        },
      ],
    },
    {
      path: "/event/:eventId/success",
      name: "registrationconfirmation",
      component: () => import("@/pages/RegistrationConfirmation.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Registration Completed";
        next();
      },
    },
    {
      path: "/event/:eventId/completed",
      name: "purchaseconfirmation",
      component: () => import("@/pages/PurchaseConfirmation.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Purchase Completed";
        next();
      },
    },
    {
      path: "/event/:eventId/registration",
      name: "public-event-registration",
      component: () => import("@/views/PublicEventRegistration.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Event registration";
        next();
      },
      children: [
        {
          path: "/event/:eventId/groupregistration",
          name: "public-event-group-registration",
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/components/GroupRegistration.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event group registration";
            next();
          },
        },
        {
          path: "/event/:eventId/checkout/:groupId?",
          name: "public-event-checkout",
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/Checkout.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Checkout";
            next();
          },
        },
        {
          path: "/event/:eventId/teamcheckout/:groupId?",
          name: "public-event-team-checkout",
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/TeamCheckout.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Checkout";
            next();
          },
        },
      ],
    },
    {
      path: "/organisers/:organizerId",
      name: "organizer",
      props: true,
      meta: { bodyClass: "noTopMarginBody" },
      component: () => import("@/pages/PrismicOrganizer.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Organizer";
        next();
      },
      children: [],
    },
    {
      path: "/clubs/",
      name: "clubs",
      props: true,
      meta: { bodyClass: "noTopMarginBody" },
      component: () => import("@/pages/clubs/PublicClubsPage.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Clubs";
        next();
      },
      children: [],
    },
    {
      path: "/clubs/create",
      name: "create-club",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/clubs/PublicCreateClub.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Create club";
        next();
      },
    },
    {
      path: "/associations/",
      name: "associations",
      props: true,
      meta: { bodyClass: "noTopMarginBody" },
      component: () =>
        import("@/pages/associations/PublicAssociationsPage.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Associations";
        next();
      },
      children: [],
    },
    {
      path: "/association/:slug",
      props: true,
      meta: { bodyClass: "noTopMarginBody" },
      component: () => import("@/pages/associations/PublicAssociationPage.vue"),
      children: [
        {
          path: "",
          name: "association",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/associations/PublicAssociationSections.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Association";
            next();
          },
        },
        {
          path: "posts",
          name: "association-posts",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/associations/PublicAssociationNews.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Association posts";
            next();
          },
        },
        {
          path: "events",
          name: "association-events",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/associations/PublicAssociationEvents.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Association events";
            next();
          },
        },
        {
          path: "clubs",
          name: "association-clubs",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/associations/PublicAssociationClubs.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Association affiliate clubs";
            next();
          },
        },
        {
          path: "clubs/pending",
          name: "association-clubs-pending",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/associations/PublicAssociationPendingClubs.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Association affiliate clubs";
            next();
          },
        },
        {
          path: "license",
          name: "association-license",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/associations/PublicAssociationLicenses.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Association licenses";
            next();
          },
        },
        {
          path: "license/tiers",
          name: "association-license-tiers",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/associations/PublicAssociationLicenseTiers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Association license tiers";
            next();
          },
        },
        {
          path: "settings",
          name: "association-settings",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/associations/PublicAssociationSettings.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Association settings";
            next();
          },
        },
      ],
    },
    {
      path: "/association/:slug/my-license",
      name: "association-my-license",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/associations/PublicAssociationUserLicense.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - My license";
        next();
      },
    },
    {
      path: "/association/:slug/add-license-type",
      name: "association-add-license-type",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/associations/AssociationAddLicenseType.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Add license type";
        next();
      },
    },
    {
      path: "/association/:slug/edit-license-type/:tierId",
      name: "association-edit-license-type",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/associations/AssociationEditLicenseType.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Manage association license";
        next();
      },
    },
    {
      path: "/association/:slug/get-license",
      name: "association-join",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/associations/AssociationJoin.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Get license";
        next();
      },
    },

    // BUSINESS
    {
      path: "/promoters/",
      name: "promoters",
      props: true,
      meta: { bodyClass: "noTopMarginBody" },
      component: () => import("@/pages/promoter/PublicPromotersPage.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Promoters";
        next();
      },
      children: [],
    },
    {
      path: "/promoters/:slug",
      props: true,
      meta: { bodyClass: "noTopMarginBody" },
      component: () => import("@/pages/promoter/PublicPromoterPage.vue"),
      children: [
        {
          path: "",
          name: "promoter",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/promoter/PublicPromoterSections.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Promoter";
            next();
          },
        },
        {
          path: "posts",
          name: "promoter-posts",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/promoter/PublicPromoterNews.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Promoter posts";
            next();
          },
        },
        {
          path: "events",
          name: "promoter-events",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/promoter/PublicPromoterEvents.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Promoter events";
            next();
          },
        },
        {
          path: "leaderboard/:text?/:division?",
          name: "promoter-leaderboard",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/promoter/PublicLeaderboardPage.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Promoter leaderboards";
            next();
          },
        },
        {
          path: "members",
          name: "promoter-members",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/promoter/PublicPromoterMembers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Promoter team members";
            next();
          },
        },
        {
          path: "members/tiers",
          name: "promoter-member-tiers",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/promoter/PublicPromoterMemberTiers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Promoter tiers";
            next();
          },
        },
        {
          path: "members/pending",
          name: "promoter-pending-members",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/promoter/PublicPromoterPendingMembers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Pending team members";
            next();
          },
        },
        {
          path: "settings",
          name: "promoter-settings",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () =>
            import("@/pages/promoter/PublicPromoterSettings.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Promoter settings";
            next();
          },
        },
      ],
    },
    {
      path: "/promoters/:slug/add-team-tier",
      name: "promoter-add-team-tier",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/clubs/ClubAddMembershipTier.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Manage roles";
        next();
      },
    },
    {
      path: "/promoters/:slug/edit-team-tier/:tierId",
      name: "promoter-edit-team-tier",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/clubs/ClubEditMembershipTier.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Manage roles";
        next();
      },
    },
    {
      path: "/promoters/:slug/join",
      name: "promoter-join",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/clubs/ClubJoin.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Join club";
        next();
      },
    },
    {
      path: "/promoter/:slug/my-membership",
      name: "promoter-my-membership",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/promoter/PublicPromoterUserMembership.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - My membership";
        next();
      },
    },
    // CLUBS
    {
      path: "/club/:slug",
      props: true,
      meta: { bodyClass: "noTopMarginBody" },
      component: () => import("@/pages/clubs/PublicClubPage.vue"),
      children: [
        {
          path: "",
          name: "club",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/clubs/PublicClubSections.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Club";
            next();
          },
        },
        {
          path: "posts",
          name: "club-posts",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/clubs/PublicClubNews.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Club posts";
            next();
          },
        },
        {
          path: "events",
          name: "club-events",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/clubs/PublicClubEvents.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Club events";
            next();
          },
        },
        {
          path: "members",
          name: "club-members",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/clubs/PublicClubMembers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Club members";
            next();
          },
        },
        {
          path: "members/tiers",
          name: "club-member-tiers",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/clubs/PublicClubMemberTiers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Club membership tiers";
            next();
          },
        },
        {
          path: "members/pending",
          name: "club-pending-members",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/clubs/PublicClubPendingMembers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Pending club members";
            next();
          },
        },
        {
          path: "settings",
          name: "club-settings",
          props: true,
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/clubs/PublicClubSettings.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Club settings";
            next();
          },
        },
      ],
    },

    {
      path: "/club/:slug/join",
      name: "club-join",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/clubs/ClubJoin.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Join club";
        next();
      },
    },
    {
      path: "/club/:slug/my-membership",
      name: "club-my-membership",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/clubs/PublicClubUserMembership.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - My membership";
        next();
      },
    },
    {
      path: "/club/:slug/add-membership-tier",
      name: "club-add-membership-tier",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/clubs/ClubAddMembershipTier.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Manage club membership tiers";
        next();
      },
    },
    {
      path: "/club/:slug/edit-membership-tier/:tierId",
      name: "club-edit-membership-tier",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/clubs/ClubEditMembershipTier.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Manage club membership tiers";
        next();
      },
    },

    {
      path: "/club/:slug/edit-course-relations",
      name: "club-edit-course-relations",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/clubs/PublicClubEditCourseRelation.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Club Course Relations";
        next();
      },
    },
    // Oganization paths
    {
      path: "/organization/:slug/manage-administrators",
      name: "organization-manage-administrators",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/OrganizationManageAdministrators.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Manage administrators";
        next();
      },
    },
    {
      path: "/organization/:slug/clublogo",
      name: "organization-logo",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/OrganizationLogoUpload.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Edit logo";
        next();
      },
    },
    {
      path: "/organization/:slug/clubcoverimage",
      name: "organization-cover-image",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/OrganizationCoverImageUpload.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Edit cover image";
        next();
      },
    },

    {
      path: "/organization/:slug/edit-post/:postId",
      name: "organization-edit-news",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/organizations/OrganizationEditPost.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Club news";
        next();
      },
    },
    {
      path: "/organization/:slug/manage-post-attachments/:postId",
      name: "organization-manage-post-attachments",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/OrganizationManagePostAttachments.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - news";
        next();
      },
    },
    {
      path: "/organization/:slug/post-news",
      name: "organization-post-news",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/organizations/OrganizationAddPost.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Add news";
        next();
      },
    },
    {
      path: "/organization/:slug/add-official",
      name: "organization-add-official",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/OrganizationAddOfficial.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Add official";
        next();
      },
    },
    {
      path: "/organization/:slug/edit-official/:officialId",
      name: "organization-edit-official",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/OrganizationEditOfficial.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Edit official";
        next();
      },
    },
    {
      path: "/organization/:slug/add-section/",
      name: "organization-add-section",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/OrganizationAddSection.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Add section";
        next();
      },
    },
    {
      path: "/organization/:slug/add-leaderboard/",
      name: "organization-add-leaderboard",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/OrganizationAddLeaderboard.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Add leaderboard";
        next();
      },
    },
    {
      path: "/organization/:slug/edit-leaderboard/:leaderboardId",
      name: "organization-edit-leaderboard",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () => import("@/pages/promoter/PromoterEditLeaderboard.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Edit leaderboard";
        next();
      },
    },
    {
      path: "/organization/:slug/edit-section/:sectionId",
      name: "organization-edit-section",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/OrganizationEditSection.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Edit section";
        next();
      },
    },
    {
      path: "/organization/:slug/edit-details",
      name: "organization-edit-contact",
      props: true,
      meta: { bodyClass: "desktop-mist-background" },
      component: () =>
        import("@/pages/organizations/PublicOrganizationEditDetails.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Edit contact details";
        next();
      },
    },
    {
      path: "/event/",
      name: "event",
      props: true,
      meta: { bodyClass: "noTopMarginBody" },
      component: () => import("@/views/PublicEvent.vue"),
      beforeEnter: (to, from, next) => {
        document.title = "Tjing - Event";
        next();
      },
      children: [
        {
          path: ":eventId",
          name: "public-event",
          redirect: { name: "public-event-results" },
        },
        {
          path: ":eventId/stats/:divisionId/:view",
          name: "eventoverview-stats",
          component: () => import("@/pages/PublicTourStats.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event stats";
            next();
          },
        },
        {
          path: ":eventId/results",
          name: "public-event-results",
          component: () =>
            import("@/pages/PublicTeamEventStandingsWrapper.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event results";
            next();
          },
        },
        {
          path: ":eventId/results/:divisionId?",
          name: "public-event-division-results",
          component: () =>
            import("@/pages/PublicTeamEventStandingsWrapper.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event results";
            next();
          },
        },
        {
          path: ":eventId/rounds",
          name: "public-event-rounds",
          meta: { bodyClass: "noTopMarginBody" },
          component: () => import("@/pages/PublicEventRounds.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event rounds";
            next();
          },
        },
        {
          //legacy path
          path: ":eventId/playerlist",
          redirect: { name: "public-event-players" },
        },
        {
          path: ":eventId/players",
          redirect: (to) => {
            return {
              name: "public-event-players",
              params: {
                ...to.params,
                view: "confirmed",
              },
            };
          },
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event players";
            next();
          },
        },
        {
          path: ":eventId/players/:view",
          name: "public-event-players",
          component: () => import("@/pages/PublicPlayers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event players";
            next();
          },
        },
        {
          path: ":eventId/teams/:view",
          name: "public-event-teams",
          component: () => import("@/pages/PublicPlayers.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event teams";
            next();
          },
        },
        {
          path: ":eventId/teams",
          redirect: (to) => {
            return {
              name: "public-event-teams",
              params: {
                ...to.params,
                view: "confirmed",
              },
            };
          },
        },
        {
          path: ":eventId/details",
          name: "public-event-details",
          component: () => import("@/pages/PublicEventDetails.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event details";
            next();
          },
        },
        {
          path: ":eventId/messages",
          name: "public-event-messages",
          component: () => import("@/pages/PublicEventMessages.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event messages";
            next();
          },
        },
        {
          path: ":eventId/shop",
          name: "public-event-shop",
          component: () => import("@/pages/PublicEventShop.vue"),
          beforeEnter: (to, from, next) => {
            document.title = "Tjing - Event shop";
            next();
          },
        },
      ],
    },
    {
      // legacypath
      path: "/event/:eventId/livepool/:poolId",
      name: "livepool",
      props: true,
      meta: { bodyClass: "full-with-padding" },
      redirect: { name: "public-event-pool-results" },
    },
  ],
});
