<template>
  <div class="tooltip-box" :class="{ 'hide-on-desktop': hideOnDesktop }">
    <slot />
    <div class="tooltip-content" :style="{ bottom: 24 + 'px' }">
      <span class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideOnDesktop: {
      type: Boolean,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    bottomOffset: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
}

.tooltip-box:hover .tooltip-content {
  opacity: 1;
  display: block;
}

.tooltip-content {
  color: white;
  text-align: center;
  padding: 4px 8px;
  border-radius: 2px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  z-index: 1;
  background: $midnight;
  border-radius: 12px;
  line-height: 1rem;
  font-size: 12px;
  @include Gilroy-Bold;
  display: none;
}
.text {
  white-space: nowrap;
  display: inline-block;
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  white-space: nowrap;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $midnight transparent transparent transparent;
}

@media (min-width: 1024px) {
  .hide-on-desktop {
    &.tooltip-box:hover .tooltip-content {
      display: none;
    }
  }
}
</style>